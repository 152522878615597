import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
// eslint-disable-next-line import/no-cycle
import store from '../state/store';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  linkExactActiveClass: 'nav-item active',
});

router.beforeEach((to, from, next) => {
  if (
    store.state.users.currentUser
    && store.state.users.currentUser.exp > Date.now() / 1000
  ) {
    return next();
  }

  if (to.path === '/login' || to.path.startsWith('/reset')) {
    return next();
  }

  return next({
    path: '/login',
  });
});

export default router;
