<template>
  <component :is="getQuestionComponent(question.questionType)" :question="question">
  </component>
</template>
<script>
import FormTemplateTextQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateTextQuestion.vue';
import FormTemplateBoolQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateBoolQuestion.vue';
import FormTemplateDateQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateDateQuestion.vue';
import FormTemplateSliderQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateSliderQuestion.vue';
import FormTemplateMultipleChoiceQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateMultipleChoiceQuestion.vue';
import FormTemplateTableQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateTableQuestion.vue';
import FormTemplateFileQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateFileQuestion.vue';
import FormTemplateMultipleChoiceSingleAnswerWithValueQuestion
  from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateMultipleChoiceSingleAnswerWithValueQuestion.vue';
import { getFormTemplateComponentFromQuestionType } from '@/utils/questionUtils';

export default {
  name: 'form-template-question-content',
  components: {
    FormTemplateTextQuestion,
    FormTemplateBoolQuestion,
    FormTemplateDateQuestion,
    FormTemplateSliderQuestion,
    'form-template-table-question': FormTemplateTableQuestion,
    FormTemplateMultipleChoiceQuestion,
    FormTemplateMultipleChoiceSingleAnswerWithValueQuestion,
    FormTemplateFileQuestion,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getQuestionComponent(questionType) {
      return getFormTemplateComponentFromQuestionType(questionType);
    },
  },
};
</script>
