/* eslint-disable import/no-cycle */
import api from '@/api/api';
import router from '@/routes/router';
import notify from '@/utils/notificationsUtils';
import form from './form';

const tasks = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  modules: {
    form,
  },
  state: {
    task: {
      id: null,
      name: null,
      hasSlides: null,
      assignee: null,
      dueDate: null,
      hoursEstimate: null,
      hoursSpent: null,
      project: {},
      state: null,
      workInstructions: null,
      workReport: null,
      formId: null,
      type: null,
    },
    workLog: [],
    workLogPagination: {
      pageNumber: 0,
      totalPages: null,
      totalElements: null,
    },
    infiniteId: +new Date(),
  },

  mutations: {
    SET_TASK: (state, task) => {
      state.task = task;
    },
    SET_TASK_WORKLOG: (state, workLog) => {
      state.workLog = workLog;
    },
    ADD_TASK_WORKLOG: (state, workLog) => {
      state.workLog.push(...workLog);
    },
    SET_PAGINATION: (state, { pageNumber, totalPages, totalElements }) => {
      state.workLogPagination = {
        pageNumber,
        totalPages,
        totalElements,
      };
    },
    CLEAR_TASK_WORKLOG: (state) => {
      state.workLog.splice(0, state.workLog.length);
    },
    RESET_PAGINATION: (state) => {
      state.workLogPagination = {
        pageNumber: 0,
        totalPages: null,
        totalElements: null,
      };
    },
    RESET_INFINITE_LOADER: (state) => {
      state.infiniteId += 1;
    },
  },
  actions: {
    fetchTask({ state, commit, dispatch }, { taskId, vm }) {
      // Automatically fetch task notes when fetching a task
      //dispatch('notes/fetchTaskNotes', { taskId, vm }, { root: true });
      const url = `/tasks/${taskId}`;
      return api.assess
        .get(url)
        .then((response) => {
          const task = response.data;
          commit('SET_TASK', task);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Task Details : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);

          // send back to project details
          router.push({
            name: 'Project Details',
            params: { projectId: this.currentProjectId },
          });
        });
    },
    resetTaskWorkLog({ commit }) {
      commit('CLEAR_TASK_WORKLOG');
      commit('RESET_PAGINATION');
      commit('RESET_INFINITE_LOADER');
    },
    fetchTaskWorkLogs({ commit, state }, { taskId, infiniteState, vm }) {
      return api.assess
        .get(`/tasks/${taskId}/worklogs?page=${state.workLogPagination.pageNumber}`)
        .then((response) => {
          const workLog = response.data.content;
          commit('ADD_TASK_WORKLOG', workLog);
          let currentPage = response.data.pageable.pageNumber;
          currentPage += 1;
          const pagination = {
            pageNumber: currentPage,
            totalPages: response.data.totalPages,
            totalElements: response.data.totalElements,
          };
          commit('SET_PAGINATION', pagination);
          if (currentPage < pagination.totalPages) {
            infiniteState.loaded();
          } else {
            if (workLog.length > 0) {
              infiniteState.loaded();
            }
            infiniteState.complete();
          }
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Task's Work Logs : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_TASK: (state) => state.task,
    GET_TASK_ID: (state) => state.task.id,
    GET_TASK_NAME: (state) => state.task.name,
    GET_TASK_WORKLOGS: (state) => state.workLog,
    GET_INFINITE_ID: (state) => state.infiniteId,
  },
};

export default tasks;
