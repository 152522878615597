<template>
  <div class="md-layout">
    <div class="titles-table-question">
      <div
        class="item md-layout-item nopadding"
        :class="tableColumnCSS"
        v-for="column in question.columns"
        :key="column.label"
      >
        <label class="question-text">{{ column.label }}</label>
      </div>
    </div>
    <div
      class="item md-layout md-layout-item md-size-100 nopadding"
      v-for="(line, lineIndex) in tableAnswers"
      :key="'line-' + lineIndex"
    >
      <div
        class="question-embedded item md-layout-item"
        :class="tableColumnCSS"
        v-for="(column, columnIndex) in question.columns"
        :key="`question-${columnIndex}-${tableAnswers[lineIndex][columnIndex].answerContent}`"
      >
        <form-template-question-embedded-content
          :questionType="column.questionTemplate.questionType"
          :readOnly="readOnly"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormTemplateQuestionEmbeddedContent from '@/components/Avalia/FormTemplates/FormTemplate/FormTemplateQuestionEmbeddedContent.vue';

export default {
  name: 'form-template-table-question',
  components: {
    FormTemplateQuestionEmbeddedContent,
  },
  props: {
    question: Object,
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableAnswers: [],
    };
  },
  computed: {
    tableColumnCSS() {
      const deleteColumnSize = 10;
      const percentColumn = Math.floor((100 - deleteColumnSize) / this.nbColumns);
      return `md-size-${percentColumn}`;
    },
    nbColumns() {
      return this.question.columns.length;
    },
  },
  watch: {},
  created() {
    // On created
    this.tableAnswers = [this.question.columns.map((c) => c.label)];
  },
  mounted() {
    // On mounted
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.question-embedded {
  padding: 0px 10px 0px 0px;
}
.add-line-button {
  margin-left: 0rem;

  color: $avalia-gray !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: $avalia-gray !important;
    opacity: 0.8 !important;
    border-color: $avalia-gray !important;
    &:active {
      color: $avalia-gray !important;
      background-color: white !important;
      border-style: solid !important;
      border-width: 2px !important;
    }
  }

  &:focus {
    color: $avalia-gray !important;
    background-color: white !important;
    border-style: solid !important;
    border-width: 2px !important;
    &:hover {
      color: white !important;
      background-color: $avalia-gray !important;
      opacity: 0.8 !important;
      border-color: $avalia-gray !important;
    }
  }
}
.titles-table-question {
  width: 100%;
  display: flex;
}
</style>
