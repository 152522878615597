/* eslint no-shadow: ["error", { "allow": ["events"] }] */
// eslint-disable-next-line import/no-cycle
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

const playbookEventsFilters = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    filters: {
      date: null,
      author: null,
      targetName: null,
      targetType: null,
    },
    options: {
      author: [],
      targetName: [],
      targetType: [],
    },
  },

  mutations: {
    RESET_FILTERS: (state) => {
      state.filters.date = null;
      state.filters.author = null;
      state.filters.targetName = null;
      state.filters.targetType = null;
    },
    RESET_FILTER_AUTHOR: (state) => {
      state.filters.author = null;
    },
    RESET_FILTER_TARGET: (state) => {
      state.filters.targetName = null;
    },
    RESET_FILTER_TARGET_TYPE: (state) => {
      state.filters.targetType = null;
    },
    SET_FILTER: (state, { key, value }) => {
      state.filters[key] = value;
    },
    SET_OPTION: (state, { key, value }) => {
      state.options[key] = value;
    },
    SET_FILTER_DATE: (state, date) => {
      state.filters.date = date;
    },
    SET_FILTER_AUTHOR: (state, author) => {
      state.filters.author = author;
    },
    SET_FILTER_TARGET: (state, targetName) => {
      state.filters.targetName = targetName;
    },
    SET_FILTER_TARGET_TYPE: (state, targetType) => {
      state.filters.targetType = targetType;
    },
  },
  actions: {
    fetchPlaybookOption({ commit }, {
      playbookId, taskId, key, valueType, vm,
    }) {
      const commandPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: {
            type: 'GetEventValuesCommand',
            playbookId,
            key,
            valueType,
          },
        },
      };
      return api.assess
        .post(`/tasks/${taskId}`, commandPayload)
        .then((response) => {
          // eslint-disable-next-line max-len
          commit('SET_OPTION', { key, value: response.data.isObject ? response.data.results.map((result) => result) : response.data.results.map((result) => result[key]) });
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Playbook Values for key ${key} : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_FILTER_DATE: (state) => state.filters.date,
    GET_FILTER_AUTHOR: (state) => state.filters.author,
    GET_FILTER_TARGET: (state) => state.filters.targetName,
    GET_FILTER_TARGET_TYPE: (state) => state.filters.targetType,
    GET_FILTERS: (state) => state.filters,
    GET_OPTIONS_AUTHOR: (state) => state.options.author,
    GET_OPTIONS_TARGET: (state) => state.options.targetName,
    GET_OPTIONS_TARGET_TYPE: (state) => state.options.targetType,
  },
};

export default playbookEventsFilters;
