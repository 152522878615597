<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <md-list>
            <!--
            <md-list-item v-if="isAdministrator" @click="showUserManagement">
              <p>User Management</p>
            </md-list-item>-->
            <md-list-item @click="showUserProfile">
              <p class="user-name">{{ currentUserDisplayName }}</p>
            </md-list-item>

            <md-list-item @click="logout">
              <i class="material-icons">input</i>
              <p class="hidden-lg hidden-md">Logout</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.getters['users/GET_CURRENT_USER'];
    },
    currentUserDisplayName() {
      return this.currentUser ? this.currentUser.displayName : 'Anonymous';
    },
    isAdministrator() {
      return this.currentUser
        ? this.currentUser.roleNames.includes(
          this.$store.getters['users/GET_ADMINISTRATOR_ROLES'],
        )
        : false;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('users/logout');
      this.$store.commit('projectsFiltersAndPagination/RESET_FILTERS_AND_PAGINATION');
      this.$store.commit('formTemplates/RESET_FILTERS_AND_PAGINATION');
      this.$router.push('/login');
    },
    showUserProfile() {
      this.$router.push('/userprofile');
    },
    showUserManagement() {
      this.$router.push('/usersmanagement');
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-name {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
</style>
