/* eslint no-shadow: ["error", { "allow": ["events"] }] */
// eslint-disable-next-line import/no-cycle

const projectsFiltersAndPagination = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    pagination: {
      perPage: 12,
      currentPage: 1,
      perPageOptions: [6, 12, 24, 48],
    },
    filters: {
      nameSearch: '',
      showArchived: false,
      showMyProjects: true,
      projectType: '',
      tags: [],
    },
  },

  mutations: {
    RESET_FILTERS_AND_PAGINATION: (state) => {
      state.filters.nameSearch = '';
      state.filters.showArchived = false;
      state.filters.showMyProjects = true;
      state.filters.projectType = '';
      state.filters.tags = [];
      state.pagination.currentPage = 1;
      state.pagination.perPage = 6;
    },
    SET_PAGINATION_CURRENT_PAGE: (state, currentPage) => {
      state.pagination.currentPage = currentPage;
    },
    SET_PAGINATION_PER_PAGE: (state, perPage) => {
      state.pagination.perPage = perPage;
    },
    SET_FILTERS_NAME: (state, nameSearch) => {
      state.filters.nameSearch = nameSearch;
    },
    SET_FILTERS_ARCHIVED: (state, showArchived) => {
      state.filters.showArchived = showArchived;
    },
    SET_FILTERS_OWN_PROJECTS: (state, showMyProjects) => {
      state.filters.showMyProjects = showMyProjects;
    },
    SET_FILTERS_PROJECT_TYPE: (state, projectType) => {
      state.filters.projectType = projectType;
    },
    SET_FILTERS_TAGS: (state, tags) => {
      state.filters.tags = tags;
    },
  },
  actions: {
  },
  getters: {
    GET_PAGINATION: (state) => state.pagination,
    GET_FILTERS: (state) => state.filters,
  },
};

export default projectsFiltersAndPagination;
