// eslint-disable-next-line import/no-cycle
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

const form = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  modules: {
  },
  state: {
    form: {
      formTemplateKey: null,
      name: null,
      sections: [],
      _id: null,
    },
  },
  mutations: {
    SET_FORM: (state, fetchedForm) => {
      state.form = fetchedForm;
    },
    RESET_FORM: (state) => {
      state.form = {
        formTemplateKey: null,
        name: null,
        sections: [],
        _id: null,
      };
    },
  },
  actions: {
    fetchForm({ commit }, { taskId, vm }) {
      const url = `/tasks/${taskId}/forms`;
      return api.assess
        .get(url)
        .then((response) => {
          const formFetch = response.data;
          commit('SET_FORM', formFetch);
        })
        .catch((error) => {
        // console.log(error);
          const errorMessage = `Could not Fetch Form : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_FORM: (state) => state.form,
  },
};

export default form;
