/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
// =========================================================
// * Vue Material Dashboard PRO - v1.3.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue';
import VueMoment from 'vue-moment';
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import VueScrollTo from 'vue-scrollto';

import Chartist from 'chartist';

import Comment from '@/components/Avalia/Comments/Comment.vue';
import CommentAnchor from '@/components/Avalia/Comments/CommentAnchor.vue';
import QuestionContent from '@/components/Avalia/TaskDetails/Form/Question/QuestionContent.vue';
import FormTemplateQuestionContent from '@/components/Avalia/FormTemplates/FormTemplate/FormTemplateQuestionContent.vue';
import ServerError from '@/pages/ServerError/ServerError.vue';
import DashboardPlugin from './material-dashboard';

// Plugins
import App from './App.vue';

// router setup
import router from './routes/router';

// vuex setup
import store from './state/store';

import 'element-ui/lib/theme-chalk/index.css';

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueMoment);
Vue.use(VueBreadcrumbs);
Vue.use(VueScrollTo);

Vue.component('Comment', Comment);
Vue.component('CommentAnchor', CommentAnchor);

Vue.component('question-content', QuestionContent);
Vue.component('form-template-question-content', FormTemplateQuestionContent);

// global library setup
Vue.prototype.$Chartist = Chartist;

Vue.config.keyCodes = {
  d: 68,
  s: 83,
  m: 77,
};

// mixpanel analytics
const mixpanel = require('mixpanel-browser');

store.dispatch('config/fetchCoreConfig')
  .then(() => {
    const propName = 'mixpanel.project.token';
    // added { cross_site_cookie: true } to solve SameSite cookie warning in Chrome
    // the option was added in release https://github.com/mixpanel/mixpanel-js/releases/tag/v2.35.0
    mixpanel.init(store.getters['config/GET_CORE_CONFIG'][propName], { cross_site_cookie: true });
    const MixpanelPlugin = {
      install(Vue, options) {
        Vue.prototype.$track = function trackEvent(eventName, eventProperties) {
          mixpanel.track(eventName, eventProperties);
        };
        Vue.prototype.$user = function trackUser(user) {
          if (user) {
            mixpanel.identify(user.sub);
            mixpanel.people.set({ $name: user.displayName });
          }
        };
      },
    };
    Vue.use(MixpanelPlugin);
  })
  .then(() => {
  /* eslint-disable no-new */
    new Vue({
      data: {
        Chartist,
      },
      render: (h) => h(App),
      router,
      store,
    }).$mount('#app');
  })
  .catch((error) => {
    new Vue({
      render: (h) => h(ServerError),
    }).$mount('#app');
  });
