<template>
  <div
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive }
    ]"
    class="wrapper"
  >
    <notifications />
    <side-bar>
      <!--
      <user-menu></user-menu>
      -->
      <mobile-menu />
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Projects',
            icon: 'home',
            path: '/projects'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Form Templates',
            icon: 'article',
            path: '/formTemplates'
          }"
        />
        <!--
        <sidebar-item :link="{ name: 'UI Experiments', icon: 'blur_on' }">
          <sidebar-item
            :link="{ name: 'UI Experiments', path: '/uiExperiments' }"
          />
          <sidebar-item
            :link="{
              name: 'Draggable Activities Planning',
              path: '/draggableActivitiesPlanning'
            }"
          />
        </sidebar-item>-->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar />
      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view />
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import {
  /* SlideYDownTransition, */ ZoomCenterTransition,
} from 'vue2-transitions';
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
// import DashboardContent from "./Content.vue";
import MobileMenu from './Extra/MobileMenu.vue';
// import UserMenu from "./Extra/UserMenu.vue";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    // DashboardContent,
    MobileMenu,
    // UserMenu,
    // SlideYDownTransition,
    ZoomCenterTransition,
  },
  mounted() {
    const docClasses = document.body.classList;
    const isWindows = navigator.platform.startsWith('Win');
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar('sidebar');
      initScrollbar('sidebar-wrapper');
      initScrollbar('main-panel');

      docClasses.add('perfect-scrollbar-on');
    } else {
      docClasses.add('perfect-scrollbar-off');
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
