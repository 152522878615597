<template>
  <div>
    <!-- Actual comment space -->
    <transition name="slide-fade">
      <div v-show="isCommentListDisplayed" class="md-layout">
        <comment
          v-for="comment in displayedComments"
          :key="comment.id"
          :comment="comment"
          @update-comment="updateComment($event, comment)"
          @delete-comment="deletedComment()"
        />
      </div>
    </transition>

    <!-- tool links -->
    <div class="md-layout md-alignement-center-space-between">
      <div v-show="isNumberOfHiddenCommentsGreaterThanZero" class="discrete">
        <a class="discrete" @click="loadMoreComments">
          {{ numberOfHiddenComments }} more comments
        </a>
      </div>
    </div>

    <!-- Add comment form -->
    <div v-show="isCommentFormDisplayedByDefault" class="md-layout">
      <div class="md-layout-item nopadding no-margin">
        <md-field>
          <label>Type your comment here</label>
          <md-input @keyup.enter="sendComment" v-model="newCommentText" ref="commentInput">
          </md-input>
          <md-button
            class="md-secondary md-sm custom-smaller-button"
            :disabled="!newCommentText"
            @click="sendComment"
          >
            Send
          </md-button>
          <md-button class="md-secondary md-sm custom-smaller-button" @click="closeAddCommentForm">
            Cancel
          </md-button>
        </md-field>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint no-param-reassign: ["error", { "props": false }] */
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

export default {
  name: 'comment-anchor',
  props: {
    isCommentListDisplayedByDefault: {
      type: Boolean,
      default: () => true,
    },
    isCommentFormDisplayedByDefault: {
      type: Boolean,
      default: () => true,
    },
    anchor: {
      type: Object,
    },
    isSelected: {
      type: Boolean,
    },
  },
  data() {
    return {
      newCommentText: '',
      displayedComments: [],
      numberOfComments: 0,
      pageSize: 3,
      currentCommentPage: 0,
      isCommentListDisplayed: true,
    };
  },
  mounted() {
    this.isCommentListDisplayed = this.isCommentListDisplayedByDefault;

    this.numberOfComments = this.anchor.numberOfComments;

    if (this.isCommentListDisplayedByDefault) {
      this.loadMoreComments();
    }
  },
  computed: {
    isNumberOfHiddenCommentsGreaterThanZero() {
      return this.numberOfHiddenComments > 0;
    },
    numberOfHiddenComments() {
      return this.numberOfComments - this.displayedComments.length;
    },
  },
  watch: {
    anchor(newValue, oldValue) {
      this.numberOfComments = newValue.numberOfComments;
      this.displayedComments = [];
      // reset the page loading
      this.currentCommentPage = 0;
      this.loadMoreComments();
    },
    isSelected(newVal, oldVal) {
      // console.log(newVal);
      if (newVal) {
        this.setFocusInput();
      }
    },
  },
  methods: {
    updateComment(event, comment) {
      comment.modifiedDate = new Date();
      comment.text = event.text;
    },
    deletedComment() {
      this.displayedComments = [];
      // reset the page loading
      this.currentCommentPage = 0;
      this.loadMoreComments();
    },
    closeAddCommentForm() {
      this.$emit('close-form');
      this.newCommentText = '';
    },
    sendComment() {
      // we don't want to send an empty comment
      if (this.newCommentText == null || this.newCommentText === '') return;

      // create the new comment object
      const newComment = {
        commentAnchorId: this.anchor.id,
        commentText: this.newCommentText,
      };

      // make api call to post new comment
      api.assess
        .post('/comments', newComment)
        .then((response) => {
          // reset form
          this.newCommentText = '';
          this.displayedComments = [];
          // reset the page loading
          this.currentCommentPage = 0;

          const successMessage = 'Comment successfully Sent';
          notify.notifySuccess(successMessage, this);
        })
      // reload comment including new one
        .then(this.loadMoreComments)
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Send Comment : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    // Call this when you want to load comments
    loadMoreComments() {
      if (this.anchor && this.anchor.id) {
        api.assess
          .get(
            `/anchors/${
              this.anchor.id
            }/tree?page=${
              this.currentCommentPage
            }&size=${
              this.pageSize}`,
          )
          .then((response) => {
            response.data.content.forEach((c) => {
              this.displayedComments.push(c);
            });
            this.numberOfComments = response.data.totalElements;
            // so we don't loose track of the next page to load
            this.currentCommentPage += 1;
            // display if it was hidden
            this.isCommentListDisplayed = true;
          })
          .catch((error) => {
            // console.log(error);
            const errorMessage = `Could not Load Comments : ${error.response.data.message || ''}`;
            notify.notifyError(errorMessage, this);
          });
      }
    },
    setFocusInput() {
      this.$nextTick(() => {
        this.$refs.commentInput.$el.focus();
      });
    },
  },
};
</script>
<style scoped>
.no-margin {
  margin: 0px 0px 0px 0px;
}
.text-right {
  text-align: right;
}
</style>
