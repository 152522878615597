<template>
  <div class="md-layout">
    <div class="nopadding md-layout-item md-large-size-90 file-input-container">
      <md-field>
        <label>Select file</label>
        <md-file class="custom-file-input" ref="file" v-model="fileName" accept="image/*" :disabled="readOnly"/>
      </md-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'form-template-file-question',
  props: {
    question: Object,
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fileName: null,
      fileObjectId: '',
    };
  },
  watch: {
    question: {
    },
  },
  computed: {
  },
  created() {
  },
  mounted() {
    // On mounted
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.text-area {
  border: 1px solid #bcc6cc;
  padding: 15px;
  width: 100%;
  min-height: 0px;
  height: auto;
}
.field:after {
  border-style: none !important;
}
.actions-container{
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
}
.file-input-container {
  &::v-deep .md-input {
    cursor: pointer;
  }
}
.custom-file-input {
  &::v-deep svg {
    fill: $avalia-gray!important; //fill: $avalia-black!important;
  }
}

</style>
