/* eslint no-shadow: ["error", { "allow": ["users"] }] */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import jwtDecode from 'jwt-decode';
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

const users = {
  // strict: process.env.NODE_ENV !== "production",
  strict: false,
  namespaced: true,
  state: {
    // Hardcoded name of the Administrator Role, could be set as an env variable
    administratorRole: 'superuser',
    currentUser: {},
    users: [],
    roles: [],
  },
  mutations: {
    UPDATE_CURRENT_USER(state, currentUser) {
      state.currentUser = currentUser;
      this._vm.$user(currentUser);
      this._vm.$track('fe_login', currentUser);
    },
    SET_USERS: (state, users) => {
      state.users = users;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
  },
  actions: {
    login(context, token) {
      api.notifyJwtTokenChanged(token);
      context.dispatch('updateCurrentUser', token);
    },
    logout(context) {
      this._vm.$track('fe_logout', context.state.currentUser);
      api.notifyJwtTokenChanged();
      context.dispatch('updateCurrentUser', undefined);
    },
    updateToken(context, token) {
      api.notifyJwtTokenChanged(token);
      context.dispatch('updateCurrentUser', token);
    },
    updateCurrentUser(context, token) {
      // Invalid token error when trying to jwtDecode a token with value "null" so had to add : && token != "null"
      if (token && token !== 'null') {
        const user = jwtDecode(token);
        context.commit('UPDATE_CURRENT_USER', user);
      } else {
        context.commit('UPDATE_CURRENT_USER', undefined);
      }
    },
    fetchUsers(context, vm) {
      return api.assess
        .get('/users')
        .then((response) => {
          const users = response.data
            ? response.data.map((user) => ({
              ...user,
              rolesNames: user.rights
                ? user.rights
                : [],
            }))
            : [];
          context.commit('SET_USERS', users);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Users : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchUserRoles(context, vm) {
      return api.idm
        .get('/api/internal/roles')
        .then((response) => {
          const roles = response.data ? response.data : [];
          context.commit('SET_ROLES', roles);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch User Roles : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    deleteUser(context, payload) {
      const { userId, vm } = payload;
      return api.idm
        .delete(`/api/internal/users/${userId}`)
        .then((response) => {
          const successMessage = 'The User has been deleted';
          notify.notifySuccess(successMessage, vm);
          context.dispatch('fetchUsers', vm);
        })
        .catch((error) => {
          const errorMessage = `User deletion failed : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_USERS: (state) => state.users,
    GET_CURRENT_USER: (state) => state.currentUser,
    GET_CURRENT_USER_EMAIL: (state) => state.currentUser.sub,
    GET_ADMINISTRATOR_ROLES: (state) => state.administratorRole,
    GET_USERS_ROLES: (state) => state.roles,
  },
};

export default users;
