<template>
  <div class="slider-container">
    <div id="slider" ref="slider"></div>
  </div>
</template>

<script>
import noUiSlider from 'nouislider';

export default {
  name: 'form-template-slider-question',
  props: {
    question: Object,
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      range: {},
      value: null,
      connect: 'lower',
      tooltips: [true],
      step: null,
      slider: null,
    };
  },
  computed: {
    nbPips() {
      const pipsPositionInPercentage = [0];
      const nbPossibilities = this.range.max - this.range.min;

      let nbPips = 5;
      if (nbPossibilities < 10) {
        nbPips = nbPossibilities;
      } else if (nbPossibilities % 5 === 0) {
        nbPips = 4;
      }
      const spaceBetweenPips = Math.floor(100 / nbPips);
      for (let i = 0; i < nbPips; i++) {
        pipsPositionInPercentage.push(spaceBetweenPips + pipsPositionInPercentage[i]);
      }
      return pipsPositionInPercentage;
    },
  },
  watch: {},
  created() {
    // On created
    this.range = {
      min: this.question.min,
      max: this.question.max,
    };
    this.step = this.question.step ? this.question.step : 1;
    this.value = this.range.min;
  },
  mounted() {
    const { slider } = this.$refs;
    this.slider = slider;
    // On mounted
    noUiSlider.create(slider, {
      start: this.value,
      range: this.range,
      connect: this.connect,
      tooltips: this.tooltips,
      step: this.step,

      /*
      format: wNumb({
        decimals: 0, // default is 2
      }), */
      format: {
        from(value) {
          return Number(value);
        },
        to(value) {
          return Number(value);
        },
      },
      pips: {
        mode: 'positions',
        values: this.nbPips,
        density: 1,
      },
    });

    if (this.readOnly) {
      slider.setAttribute('disabled', true);
    } else {
      // eslint-disable-next-line func-names
    }
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.slider-container{
  padding-top: 15px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 30px;
}
</style>
