// eslint-disable-next-line import/no-cycle
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

const playbooks = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  modules: {
  },
  state: {
    playbook: {
      _id: null,
      name: null,
    },
  },
  mutations: {
    SET_PLAYBOOK: (state, fetchedPlaybook) => {
      state.playbook = fetchedPlaybook;
    },
    SET_PLAYBOOK_ACTIVITIES: (state, activities) => {
      state.playbook.activities = activities;
    },
    RESET_PLAYBOOK: (state) => {
      state.playbook = {
        _id: null,
        name: null,
      };
    },
  },
  actions: {
    fetchPlaybook({ commit }, { taskId, vm }) {
      const url = `/tasks/${taskId}/playbooks`;
      return api.assess
        .get(url)
        .then((response) => {
          const fetchedPlaybook = response.data;
          commit('SET_PLAYBOOK', fetchedPlaybook);
        })
        .catch((error) => {
        // console.log(error);
          const errorMessage = `Could not Fetch Playbook : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_PLAYBOOK: (state) => state.playbook,
    //GET_PLAYBOOK_ACTIVITIES: (state) => state.playbook.activities,
  },
};

export default playbooks;
