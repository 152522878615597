/* eslint no-shadow: ["error", { "allow": ["feedbacks"] }] */
/* eslint-disable import/no-cycle */
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

const feedbacks = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    feedbacks: [],
    pagination: {
      pageNumber: 0,
      totalPages: null,
      totalElements: null,
    },
    infiniteId: +new Date(),
  },

  mutations: {
    CLEAR_PROJECT_FEEDBACKS: (state) => {
      state.feedbacks.splice(0, state.feedbacks.length);
    },
    SET_PROJECT_FEEDBACKS: (state, feedbacks) => {
      state.feedbacks = feedbacks;
    },
    ADD_PROJECT_FEEDBACKS: (state, feedbacks) => {
      state.feedbacks.push(...feedbacks);
    },
    RESET_PAGINATION: (state) => {
      state.pagination = {
        pageNumber: 0,
        totalPages: null,
        totalElements: null,
      };
    },
    SET_PAGINATION: (state, { pageNumber, totalPages, totalElements }) => {
      state.pagination = {
        pageNumber,
        totalPages,
        totalElements,
      };
    },
    RESET_INFINITE_LOADER: (state) => {
      state.infiniteId += 1;
    },
  },
  actions: {
    resetFeedbacks({ commit }) {
      commit('CLEAR_PROJECT_FEEDBACKS');
      commit('RESET_PAGINATION');
      commit('RESET_INFINITE_LOADER');
    },
    fetchProjectFeedbacks(
      { commit, state },
      {
        userEmail, projectId, infiniteState, vm,
      },
    ) {
      let url = `/feedbacks?projectId=${projectId}&page=${state.pagination.pageNumber}`;
      if (userEmail && userEmail.length > 0) {
        url += `&userEmail=${userEmail}`;
      }
      api.assess
        .get(url)
        .then((response) => {
          const feedbacks = response.data.content;
          commit('ADD_PROJECT_FEEDBACKS', feedbacks);
          let currentPage = response.data.pageable.pageNumber;
          currentPage += 1;
          const pagination = {
            pageNumber: currentPage,
            totalPages: response.data.totalPages,
            totalElements: response.data.totalElements,
          };
          commit('SET_PAGINATION', pagination);
          if (currentPage < pagination.totalPages) {
            infiniteState.loaded();
          } else {
            if (feedbacks.length > 0) {
              infiniteState.loaded();
            }
            infiniteState.complete();
          }
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Project's Feedbacks : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_FEEDBACKS: (state) => state.feedbacks,
    GET_PAGINATION: (state) => state.pagination,
    GET_INFINITE_ID: (state) => state.infiniteId,
  },
};

export default feedbacks;
