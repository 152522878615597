<template>
  <div>
    <div
      v-for="(choice, index) in question.choices"
      :key="index"
      class="md-layout-item md-size-100"
    >
      <md-checkbox
        v-model="question.answer.answerContent"
        :value="choice"
        :disabled="isTaskReadOnly"
        @change="checkboxButtonValueChanged"
      >
        {{ choice }}
      </md-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'multiple-choice-question',
  props: {
    question: Object,
    isTaskReadOnly: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
    checkboxButtonValueChanged(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
