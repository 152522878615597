function getComponentFromQuestionType(questionType) {
  let component;
  switch (questionType) {
    case 'TEXT_QUESTION':
      component = 'text-question';
      break;
    case 'MULTIPLE_CHOICE_QUESTION':
      component = 'multiple-choice-question';
      break;
    case 'BOOL_QUESTION':
      component = 'bool-question';
      break;
    case 'SLIDER_QUESTION':
      component = 'slider-question';
      break;
    case 'SLIDER_FLOAT_QUESTION':
      component = 'slider-question';
      break;
    case 'MULTIPLE_CHOICE_SINGLE_ANSWER_WITH_VALUE_QUESTION':
      component = 'multiple-choice-single-answer-with-value-question';
      break;
    case 'DATE_QUESTION':
      component = 'date-question';
      break;
    case 'TABLE_QUESTION':
      component = 'table-question';
      break;
    case 'FILE_QUESTION':
      component = 'file-question';
      break;
    default:
      component = 'text-question';
  }
  return component;
}

function getFormTemplateComponentFromQuestionType(questionType) {
  let component;
  switch (questionType) {
    case 'TEXT_QUESTION':
      component = 'form-template-text-question';
      break;
    case 'MULTIPLE_CHOICE_QUESTION':
      component = 'form-template-multiple-choice-question';
      break;
    case 'BOOL_QUESTION':
      component = 'form-template-bool-question';
      break;
    case 'SLIDER_QUESTION':
      component = 'form-template-slider-question';
      break;
    case 'SLIDER_FLOAT_QUESTION':
      component = 'form-template-slider-question';
      break;
    case 'MULTIPLE_CHOICE_SINGLE_ANSWER_WITH_VALUE_QUESTION':
      component = 'form-template-multiple-choice-single-answer-with-value-question';
      break;
    case 'DATE_QUESTION':
      component = 'form-template-date-question';
      break;
    case 'TABLE_QUESTION':
      component = 'form-template-table-question';
      break;
    case 'FILE_QUESTION':
      component = 'form-template-file-question';
      break;
    default:
      component = 'form-template-text-question';
  }
  return component;
}

function getDefaultValue(questionType) {
  let defaultValue;
  switch (questionType) {
    case 'TEXT_QUESTION':
      defaultValue = '';
      break;
    case 'MULTIPLE_CHOICE_QUESTION':
      defaultValue = [];
      break;
    case 'BOOL_QUESTION':
      defaultValue = true;
      break;
    case 'SLIDER_QUESTION':
      defaultValue = 0;
      break;
    case 'MULTIPLE_CHOICE_SINGLE_ANSWER_WITH_VALUE_QUESTION':
      defaultValue = [];
      break;
    case 'DATE_QUESTION':
      defaultValue = new Date();
      break;
    case 'TABLE_QUESTION':
      defaultValue = [];
      break;
    case 'FILE_QUESTION':
      defaultValue = '';
      break;
    default:
      defaultValue = '';
  }
  return {
    answerContent: defaultValue,
  };
}

function createQuestionFromAnswerAndType(answer, questionType) {
  let answerValue = answer;
  if (!answer) {
    answerValue = getDefaultValue(questionType);
  }
  return {
    answer: answerValue,
    detail: [],
    questionType,
  };
}

export {
  getComponentFromQuestionType,
  getFormTemplateComponentFromQuestionType,
  createQuestionFromAnswerAndType,
};
