function notifyError(errorMessage, vm) {
  vm.$notify({
    message: errorMessage,
    icon: 'clear',
    type: 'danger',
    timeout: 4000,
    horizontalAlign: 'right',
    verticalAlign: 'top',
  });
}

function notifyWarning(errorMessage, vm) {
  vm.$notify({
    message: errorMessage,
    icon: 'warning',
    type: 'warning',
    timeout: 4000,
    horizontalAlign: 'right',
    verticalAlign: 'top',
  });
}

// For success we use the default timeout of 3000ms defined in Notifications.vue
function notifySuccess(successMessage, vm) {
  vm.$notify({
    message: successMessage,
    icon: 'done',
    type: 'success',
    horizontalAlign: 'right',
    verticalAlign: 'top',
  });
}

export default {
  notifyError,
  notifyWarning,
  notifySuccess,
};
