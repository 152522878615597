import DashboardLayout from '@/layout/Dashboard/DashboardLayout.vue';
import AuthLayout from '@/layout/Auth/AuthLayout.vue';

// Avalia Pages
// Project Pages
const Projects = () => import('@/pages/Projects/ProjectsOverview.vue');

const ProjectDetails = () => import('@/pages/ProjectDetails/ProjectDetails.vue');

const ProjectNotesExplorer = () => import('@/pages/ProjectNotesExplorer/ProjectNotesExplorer.vue');

// Tasks Pages
const TaskDetails = () => import('@/pages/Tasks/TaskDetails.vue');

// IDM Pages
const UsersManagement = () => import('@/pages/UsersManagement/UsersManagement.vue');
const UserProfile = () => import('@/pages/UserProfile/UserProfile.vue');

// Form Templates Pages
const FormTemplatesOverview = () => import('@/pages/FormTemplates/FormTemplatesOverview.vue');
const FormTemplateDetails = () => import('@/pages/FormTemplates/FormTemplateDetails.vue');

// Pages
const LoginGoogle = () => import('@/pages/LoginGoogle.vue');
const Invitation = () => import('@/pages/Invitation.vue');

const authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: LoginGoogle,
    },
    {
      path: '/invitation',
      name: 'Invitation',
      component: Invitation,
    },
  ],
};

const routes = [
  {
    path: '/',
    redirect: '/projects',
    name: 'Home',
  },
  authPages,
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: 'projects',
        name: 'Projects',
        components: { default: Projects },
      },
      {
        path: 'project/:projectId',
        name: 'Project Details',
        components: {
          default: ProjectDetails,
        },
      },
      {
        path: 'project/:projectId/notes/',
        name: 'Project Notes',
        components: {
          default: ProjectNotesExplorer,
        },
      },
      {
        path: '/project/:projectId/task/:taskId',
        name: 'Task Details',
        components: { default: TaskDetails },
      },
      {
        path: 'formTemplates',
        name: 'Form Templates',
        components: { default: FormTemplatesOverview },
      },
      {
        path: '/formTemplates/:formTemplateVersionId',
        name: 'Form Template Version Details',
        components: { default: FormTemplateDetails },
      },
      // User Management (IDM) Routes
      {
        path: 'usersmanagement',
        name: 'Users Management',
        components: { default: UsersManagement },
      },
      {
        path: 'userprofile',
        name: 'User Profile',
        component: UserProfile,
      },
    ],
  },
];

export default routes;
