<template>
  <div class="md-layout">
    <div
      v-for="(choice, index) in question.choices"
      :key="index"
      class="md-layout-item md-size-100 md-layout question-choice"
    >
      <div class="md-layout-item md-size-80">
        <md-radio
          :value="choice.key"
          :disabled="readOnly"
          class="question-choice-label"
        >
          {{ choice.key }}
        </md-radio>
      </div>
      <div class="md-layout-item md-size-10">
        <md-field>
          <label>Value</label>
          <md-input class="value-input" v-model="choice.value" :disabled="readOnly"/>
        </md-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'form-template-multiple-choice-single-answer-with-value-question',
  components: { },
  props: {
    question: Object,
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.question-choice-value{
  margin: 10px 10px 10px 0;
  height: auto;
}
.value-input{
  padding-left: 1rem;
}
</style>
<style lang="scss">
.md-radio .md-radio-label{
    //word-wrap: break-word !important;
    height: auto
}

</style>
