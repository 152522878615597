<template>
  <textarea class="text-area" :class="computeCursorClass" v-model.lazy="text" :disabled="readOnly"></textarea>
</template>

<script>
export default {
  name: 'form-template-text-question',
  props: {
    question: Object,
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      text: '',
    };
  },
  watch: {
  },
  computed: {
    computeCursorClass() {
      return this.readOnly ? 'cursor-not-allowed' : '';
    },
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.text-area {
  border: 1px solid #bcc6cc;
  padding: 15px;
  width: 100%;
  min-height: 0px;
  height: auto;
}
.field:after {
  border-style: none!important;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
