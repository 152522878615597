<template>
  <div>
    <div
      v-for="(choice, index) in question.choices"
      :key="index"
      class="md-layout-item md-size-100"
    >
      <md-checkbox
        :value="choice"
        :disabled="readOnly"
      >
        {{ choice }}
      </md-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'form-template-multiple-choice-question',
  props: {
    question: Object,
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped></style>
