// eslint-disable-next-line import/no-cycle
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

const notes = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  modules: {
  },
  state: {
    taskNotes: [],
    projectNotes: [],
  },
  mutations: {
    SET_TASK_NOTES: (state, taskNotes) => {
      state.taskNotes = taskNotes;
    },
    SET_PROJECT_NOTES: (state, projectNotes) => {
      state.projectNotes = projectNotes;
    },
  },
  actions: {
    fetchTaskNotes({ commit }, { taskId, vm }) {
      const url = `/tasks/${taskId}/notes`;
      return api.assess
        .get(url)
        .then((response) => {
          const taskNotes = response.data;
          commit('SET_TASK_NOTES', taskNotes);
        })
        .catch((error) => {
        // console.log(error);
          const errorMessage = `Could not Fetch Task Notes : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchProjectNotes({ commit }, { projectId, vm }) {
      const url = `/projects/${projectId}/notes`;
      return api.assess
        .get(url)
        .then((response) => {
          const projectNotes = response.data;
          commit('SET_PROJECT_NOTES', projectNotes);
        })
        .catch((error) => {
        // console.log(error);
          const errorMessage = `Could not Fetch Project Notes : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_TASK_NOTES: (state) => state.taskNotes,
    GET_PROJECT_NOTES: (state) => state.projectNotes,
  },
};

export default notes;
