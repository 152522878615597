/* eslint-disable import/no-cycle */
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

const formTemplates = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  modules: {
  },
  state: {
    formTemplates: [],
    loading: false,
    total: 0,
    pagination: {
      perPage: 24,
      currentPage: 1,
      perPageOptions: [6, 12, 24, 48],
    },
    filters: {
      tags: [],
    },
    formTemplateVersion: {
      id: null,
      formTemplateObjectId: null,
      name: null,
      formTemplateKey: null,
      version: null,
    },
    formTemplate: {
      id: null,
      formTemplateKey: null,
      versions: [],
      tags: [],
    },
    form: {
      _id: null,
      name: null,
      formTemplateKey: null,
      version: null,
      sections: [],
    },
  },

  mutations: {
    SET_FORM_TEMPLATES: (state, mutationPayload) => {
      state.formTemplates = mutationPayload.formTemplates;
      state.total = mutationPayload.total;
    },
    SET_FORM_TEMPLATE: (state, formTemplate) => {
      state.formTemplate = formTemplate;
    },
    SET_FORM_TEMPLATE_VERSION: (state, formTemplateVersion) => {
      state.formTemplateVersion = formTemplateVersion;
    },
    SET_FORM_TEMPLATE_VERSION_FORM: (state, form) => {
      state.form = form;
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading;
    },
    RESET_FILTERS_AND_PAGINATION: (state) => {
      state.filters.tags = [];
      state.pagination.currentPage = 1;
      state.pagination.perPage = 24;
    },
    SET_PAGINATION_CURRENT_PAGE: (state, currentPage) => {
      state.pagination.currentPage = currentPage;
    },
    SET_PAGINATION_PER_PAGE: (state, perPage) => {
      state.pagination.perPage = perPage;
    },
    SET_FILTERS_TAGS: (state, tags) => {
      state.filters.tags = tags;
    },
  },
  actions: {
    fetchFormTemplates({ commit }, {
      perPage, currentPage, tags, vm,
    }) {
      let url = `/formTemplates?page=${currentPage - 1}&size=${perPage}`;

      if (tags && tags.length > 0) {
        url += `&tags=${tags.map((t) => t.text.toLowerCase()).join(',')}`;
      }
      return api.assess
        .get(url)
        .then((response) => {
          const mutationPayload = {};
          mutationPayload.formTemplates = response.data.content;
          mutationPayload.total = response.data.totalElements;
          commit('SET_FORM_TEMPLATES', mutationPayload);
        })
        .catch((error) => {
          const errorMessage = `Could not Fetch Form Templates : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchFormTemplateByFormTemplateKey({ commit }, { formTemplateKey, vm }) {
      return api.assess
        .get(`/formTemplates/byFormTemplateKey?formTemplateKey=${formTemplateKey}`)
        .then((response) => {
          const formTemplate = response.data;
          commit('SET_FORM_TEMPLATE', formTemplate);
        })
        .catch((error) => {
          const errorMessage = `Could not Fetch Form Template : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchFormTemplateById({ commit }, { formTemplateId, vm }) {
      return api.assess
        .get(`/formTemplates/${formTemplateId}`)
        .then((response) => {
          const formTemplate = response.data;
          commit('SET_FORM_TEMPLATE', formTemplate);
        })
        .catch((error) => {
          const errorMessage = `Could not Fetch Form Template : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchFormTemplateVersion({ commit }, { formTemplateVersionId, vm }) {
      return api.assess
        .get(`/formTemplateVersions/${formTemplateVersionId}`)
        .then((response) => {
          const formTemplateVersion = response.data;
          commit('SET_FORM_TEMPLATE_VERSION', formTemplateVersion);
        })
        .catch((error) => {
          const errorMessage = `Could not Fetch Form Template Version : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchFormTemplateVersionForm({ commit }, { formTemplateVersionId, vm }) {
      return api.assess
        .get(`/formTemplateVersions/${formTemplateVersionId}/form`)
        .then((response) => {
          const form = response.data;
          commit('SET_FORM_TEMPLATE_VERSION_FORM', form);
        })
        .catch((error) => {
          const errorMessage = `Could not Fetch Form Template Version's Form : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_FORM_TEMPLATES: (state) => state.formTemplates,
    GET_FORM_TEMPLATE: (state) => state.formTemplate,
    GET_FORM_TEMPLATE_VERSION: (state) => state.formTemplateVersion,
    GET_FORM_TEMPLATE_VERSION_FORM: (state) => state.form,
    GET_FORM_TEMPLATES_TOTAL: (state) => state.total,
    GET_LOADING: (state) => state.loading,
    GET_PAGINATION: (state) => state.pagination,
    GET_FILTERS: (state) => state.filters,
  },
};

export default formTemplates;
