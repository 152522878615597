/* eslint no-shadow: ["error", { "allow": ["events"] }] */
// eslint-disable-next-line import/no-cycle
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

const events = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    events: [],
    pagination: {
      pageNumber: 0,
      totalPages: null,
      totalElements: null,
    },
    infiniteId: +new Date(),
  },

  mutations: {
    CLEAR_PROJECT_EVENTS: (state) => {
      state.events.splice(0, state.events.length);
    },
    SET_PROJECT_EVENTS: (state, events) => {
      state.events = events;
    },
    ADD_PROJECT_EVENTS: (state, events) => {
      state.events.push(...events);
    },
    RESET_PAGINATION: (state) => {
      state.pagination = {
        pageNumber: 0,
        totalPages: null,
        totalElements: null,
      };
    },
    SET_PAGINATION: (state, { pageNumber, totalPages, totalElements }) => {
      state.pagination = {
        pageNumber,
        totalPages,
        totalElements,
      };
    },
    RESET_INFINITE_LOADER: (state) => {
      state.infiniteId += 1;
    },
  },
  actions: {
    resetEvents({ commit }) {
      commit('CLEAR_PROJECT_EVENTS');
      commit('RESET_PAGINATION');
      commit('RESET_INFINITE_LOADER');
    },
    fetchProjectEvents({ commit, state }, { projectId, infiniteState, vm }) {
      api.assess
        .get(`/events?projectId=${projectId}&page=${state.pagination.pageNumber}`)
        .then((response) => {
          const events = response.data.content;
          commit('ADD_PROJECT_EVENTS', events);
          let currentPage = response.data.pageable.pageNumber;
          currentPage += 1;
          const pagination = {
            pageNumber: currentPage,
            totalPages: response.data.totalPages,
            totalElements: response.data.totalElements,
          };
          commit('SET_PAGINATION', pagination);
          if (currentPage < pagination.totalPages) {
            infiniteState.loaded();
          } else {
            if (events.length > 0) {
              infiniteState.loaded();
            }
            infiniteState.complete();
          }
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Project's Events : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_EVENTS: (state) => state.events,
    GET_PAGINATION: (state) => state.pagination,
    GET_INFINITE_ID: (state) => state.infiniteId,
  },
};

export default events;
