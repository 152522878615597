/* eslint-disable import/no-cycle */
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

const projects = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  modules: {
  },
  state: {
    projects: [],
    loading: false,
    total: 0,
    roles: [],
    caseDefinitions: [],
  },

  mutations: {
    SET_POSSIBLE_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PROJECTS: (state, mutationPayload) => {
      state.projects = mutationPayload.projects;
      state.total = mutationPayload.total;
    },
    SET_CASE_DEFINITIONS: (state, caseDefinitions) => {
      state.caseDefinitions = caseDefinitions;
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading;
    },
  },
  actions: {
    fetchProjects({ commit }, {
      tags, name, projectType, showArchived, users, perPage, currentPage, vm,
    }) {
      let url = `/projects?page=${currentPage - 1}&size=${perPage}`;
      if (tags && tags.length > 0) {
        url += `&tags=${tags.map((t) => t.text.toLowerCase()).join(',')}`;
      }
      if (name && name.length > 0) {
        url += `&name=${name.toLowerCase()}`;
      }
      if (projectType && projectType.length > 0) {
        url += `&caseType=${projectType}`;
      }

      url += `&isArchived=${showArchived}`;
      if (users.length > 0) {
        url += `&users=${users}`;
      }

      return api.assess
        .get(url)
        .then((response) => {
          const mutationPayload = {};
          mutationPayload.projects = response.data.content;
          mutationPayload.total = response.data.totalElements;
          commit('SET_PROJECTS', mutationPayload);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Project : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchAllProjectRoles({ commit }, vm) {
      return api.assess
        .get('/roles')
        .then((response) => {
          const roles = response.data;
          commit('SET_POSSIBLE_ROLES', roles);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Roles : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchProjectDetails({ commit }, { projectId, vm }) {
      return api.assess
        .get(`/projects/${projectId}`)
        .then((response) => {
          const project = response.data;
          commit('SET_PROJECT_DETAILS', project);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Project Details : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchCaseDefinitions({ commit }, vm) {
      return api.assess
        .get('/cases')
        .then((response) => {
          const caseDefinitions = response.data;
          commit('SET_CASE_DEFINITIONS', caseDefinitions);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Case Definitions : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_PROJECTS: (state) => state.projects,
    GET_PROJECTS_TOTAL: (state) => state.total,
    GET_POSSIBLE_ROLES: (state) => state.roles,
    GET_CASE_DEFINITIONS: (state) => state.caseDefinitions,
    GET_LOADING: (state) => state.loading,
  },
};

export default projects;
