/* eslint-disable import/no-cycle */
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import router from '@/routes/router';

const project = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  modules: {
  },
  state: {
    project: {
      id: null,
      name: null,
      avatar: null,
      description: null,
      type: null,
      users: [],
      lastUpdate: null,
      currentState: null,
      currentStateIcon: null,
      health: null,
      milestones: [],
      tasks: [],
      anchor: {
        creatorEmail: null,
        createdDate: null,
        modifiedDate: null,
        id: null,
        numberOfComments: null,
      },
    },
    completedTasks: [],
    lockedTasks: [],
    loadingStartTask: {
      taskId: null,
      loading: false,
    },
  },
  mutations: {
    SET_PROJECT_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PROJECT_DETAILS: (state, projectDetails) => {
      state.project = projectDetails;
    },
    SET_COMPLETED_TASKS: (state, completedTasks) => {
      state.completedTasks = completedTasks;
    },
    SET_LOCKED_TASKS: (state, lockedTasks) => {
      state.lockedTasks = lockedTasks;
    },
    SET_LOADING_START_TASK: (state, { id, loading }) => {
      state.loadingStartTask = {
        taskId: id,
        loading,
      };
    },
  },
  actions: {
    fetchProjectDetails({ commit }, { projectId, vm }) {
      return api.assess
        .get(`/projects/${projectId}`)
        .then((response) => {
          const projectDetails = response.data;
          commit('SET_PROJECT_DETAILS', projectDetails);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Project Details : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchProjectCompletedTasks({ commit }, { id, vm }) {
      return api.assess
        .get(`/projects/${id}/tasks/completed`)
        .then((response) => {
          const completedTasks = response.data.content;
          commit('SET_COMPLETED_TASKS', completedTasks);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Project's Completed Tasks : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchProjectLockedTasks({ commit }, { id, vm }) {
      return api.assess
        .get(`/projects/${id}/tasks/locked`)
        .then((response) => {
          const lockedTasks = response.data;
          commit('SET_LOCKED_TASKS', lockedTasks);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Project's Locked Tasks : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    startProjectTask({ commit, state }, { taskId, vm }) {
      commit('SET_LOADING_START_TASK', { id: taskId, loading: true });
      const workPayload = {
        action: 'START',
        taskWorkDTO: null,
      };
      return api.assess
        .post(`/tasks/${taskId}`, workPayload)
        .then((response) => {
          commit('SET_LOADING_START_TASK', { id: null, loading: false });
          router.push({
            name: 'Task Details',
            params: { projectId: state.project.id, taskId },
          });
        })
        .catch((error) => {
          // console.log(error);
          commit('SET_LOADING_START_TASK', { id: null, loading: false });
          const errorMessage = `Could not Start Task : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_PROJECT: (state) => state.project,
    GET_PROJECT_ANCHOR: (state) => state.project.anchor,
    GET_PROJECT_ID: (state) => state.project.id,
    GET_PROJECT_NAME: (state) => state.project.name,
    GET_COMPLETED_TASKS: (state) => state.completedTasks,
    GET_LOCKED_TASKS: (state) => state.lockedTasks,
    GET_START_TASK_LOADING: (state) => state.loadingStartTask,
  },
};

export default project;
