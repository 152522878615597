<template>
  <div class="md-layout">
    <div
      v-for="(choice, index) in question.choices"
      :key="index"
      class="md-layout-item md-size-100 question-choice"
    >
      <md-radio
        v-model="question.answer.answerContent.key"
        :value="choice.key"
        :disabled="isTaskReadOnly"
        @change="radioButtonValueChanged(choice)"
        class="question-choice-label"
      >
        {{ choice.key }}
      </md-radio>
    </div>
  </div>
</template>

<script>
export default {
  name: 'multiple-choice-single-answer-with-value-question',
  components: { },
  props: {
    question: Object,
    notes: {
      type: Array,
      default: () => [],
    },
    isTaskReadOnly: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
    radioButtonValueChanged(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.question-choice {
  //padding-top: 0.5rem;
  //padding-bottom: 1rem;
  //display: inline-block;
  //height: auto;
}
.question-choice-label{
  //display: flex;
  //flex-wrap: wrap;
}
.radio-label {
  //display: flex;
  //flex-wrap: wrap;
  //display: inline-block;
}

.md-radio-label #text{
  //overflow: visible !important;
}
</style>
<style lang="scss">
.md-radio .md-radio-label{
    //word-wrap: break-word !important;
    height: auto
}
</style>
