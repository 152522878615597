import axios from 'axios';
// eslint-disable-next-line import/no-cycle
//import router from '@/routes/router';

const assess = axios.create({
  /* baseURL: process.env.VUE_APP_ASSESS_BACKEND_API_URL, */
  baseURL: process.env.VUE_APP_ASSESS_BACKEND_API_URL, // "http://localhost:8080/",
  withCredentials: true,
});

const idm = axios.create({
  /* baseURL: process.env.VUE_APP_IDM_BACKEND_API_URL, */
  baseURL: process.env.VUE_APP_IDM_BACKEND_API_URL, // "http://localhost:8083/"
  withCredentials: true,
});

assess.interceptors.response.use(
  (res) => res,
  (error) => {
    // Redirecting to login page when user is not authenticated
    if (error.response && error.response.status === 401) {
      // console.log("I should logout...");
      this.$router.push('/login');
    }
    // Redirecting to projects overview page when user tries to access a resource he doesn't have access rights to
    if (error.response && error.response.status === 403) {
      // console.log("I should redirect to Projects Overview page...");
      this.$router.push('/projects');
    }
    return Promise.reject(error);
  },
);

/*
 * Make sure to call this function whenever the user has logged in or out
 */
function notifyJwtTokenChanged(token) {
  if (token) {
    const header = `Bearer ${token}`;
    assess.defaults.headers.common.Authorization = header;
    idm.defaults.headers.common.Authorization = header;
  } else {
    assess.defaults.headers.common.Authorization = undefined;
    idm.defaults.headers.common.Authorization = undefined;
  }
}

notifyJwtTokenChanged();

export default {
  notifyJwtTokenChanged,
  assess,
  idm,
};
