/* eslint no-shadow: ["error", { "allow": ["tags"] }] */
// eslint-disable-next-line import/no-cycle
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

const tags = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    projectTags: [],
    formTemplateTags: [],
  },

  mutations: {
    SET_PROJECT_TAGS: (state, tags) => {
      state.projectTags = tags;
    },
    SET_FORM_TEMPLATE_TAGS: (state, tags) => {
      state.formTemplateTags = tags;
    },
  },
  actions: {
    fetchProjectTags({ commit }, vm) {
      const url = '/tags?type=project tag';
      return api.assess
        .get(url)
        .then((response) => {
          // eslint-disable-next-line no-underscore-dangle
          const tags = response.data._embedded && response.data._embedded.tags ? response.data._embedded.tags : [];
          commit('SET_PROJECT_TAGS', tags);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Project's Tags : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
    fetchFormTemplateTags({ commit }, vm) {
      const url = '/tags?type=form template tag';
      return api.assess
        .get(url)
        .then((response) => {
          // eslint-disable-next-line no-underscore-dangle
          const tags = response.data._embedded && response.data._embedded.tags ? response.data._embedded.tags : [];
          commit('SET_FORM_TEMPLATE_TAGS', tags);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Form Templates Tags : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, vm);
        });
    },
  },
  getters: {
    GET_PROJECT_TAGS: (state) => state.projectTags,
    GET_FORM_TEMPLATE_TAGS: (state) => state.formTemplateTags,
  },
};

export default tags;
