// eslint-disable-next-line import/no-cycle
import api from '@/api/api';

const config = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    coreConfig: undefined,
  },

  mutations: {
    SET_CORE_CONFIG: (state, coreConfig) => {
      state.coreConfig = coreConfig;
    },
  },
  actions: {
    fetchCoreConfig({ commit }) {
      return api.assess.get('/config/core')
        .then((response) => {
          commit('SET_CORE_CONFIG', response.data);
        })
        .catch((error) => {
          throw new Error('Enable to fetch server config.');
        });
    },
  },
  getters: {
    GET_CORE_CONFIG: (state) => state.coreConfig,
  },
};

export default config;
