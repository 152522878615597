<template>
  <component :is="getQuestionComponent(questionType)" :question="questionEmbedded" :isTaskReadOnly="readOnly">
  </component>
</template>
<script>
import FormTemplateTextQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateTextQuestion.vue';
import FormTemplateBoolQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateBoolQuestion.vue';
import FormTemplateDateQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateDateQuestion.vue';
import FormTemplateSliderQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateSliderQuestion.vue';
import FormTemplateMultipleChoiceQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateMultipleChoiceQuestion.vue';
import FormTemplateTableQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateTableQuestion.vue';
import FormTemplateFileQuestion from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateFileQuestion.vue';
import FormTemplateMultipleChoiceSingleAnswerWithValueQuestion
  from '@/components/Avalia/FormTemplates/FormTemplate/QuestionType/FormTemplateMultipleChoiceSingleAnswerWithValueQuestion.vue';
import { getFormTemplateComponentFromQuestionType } from '@/utils/questionUtils';

export default {
  name: 'form-template-question-embedded-content',
  components: {
    FormTemplateTextQuestion,
    FormTemplateBoolQuestion,
    FormTemplateDateQuestion,
    FormTemplateSliderQuestion,
    'form-template-table-question': FormTemplateTableQuestion,
    FormTemplateMultipleChoiceQuestion,
    FormTemplateMultipleChoiceSingleAnswerWithValueQuestion,
    FormTemplateFileQuestion,
  },
  props: {
    questionType: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      questionEmbedded: {},
    };
  },
  methods: {
    getQuestionComponent(questionType) {
      return getFormTemplateComponentFromQuestionType(questionType);
    },
  },
  created() {
    this.questionEmbedded.questionType = this.questionType;
  },
  watch: {
    answer(newAnswer) {
      this.$set(this.questionEmbedded, 'answer', newAnswer);
    },
  },
};
</script>
