<template>
  <div class="md-layout-item md-size-100 md-layout observation">
    <div class="observation-body-outter md-layout-item md-size-100">
      <div class="observation-body-inner">
        <div class="observation-text">
          <span v-if="comment.author != null" class="authorName">{{
            comment.author.displayName
          }}</span>
          <span v-else class="authorName">
            Anonymous Guest
          </span>
          {{ comment.text }}
        </div>
        <div v-show="isEditFormDisplayed">
          <div class="md-layout-item nopadding no-margin">
            <md-field>
              <label>Edit your comment here</label>
              <md-input @keyup.enter="editComment" v-model="editedComment" ref="editInput">
              </md-input>
              <md-button
                class="md-secondary md-sm"
                :disabled="editedComment == comment.text"
                @click="editComment"
              >
                Edit
              </md-button>
              <md-button class="md-secondary md-sm" @click="closeEditForm">
                Cancel
              </md-button>
            </md-field>
          </div>
        </div>
        <div>
          <a @click="toggleReactionPicker" class="discrete">
            react
          </a>
          <!-- reply -->
          <a @click="toggleReplyForm" class="discrete">
            &#8226; {{ replyButtonText }}
          </a>
          <span
            v-if="
              comment.author != null &&
                comment.author.email == loggedInUserEmail
            "
          >
            <a @click="toggleEditForm" class="discrete">
              &#8226; edit
            </a>
            <a @click="deleteComment" class="discrete">
              &#8226; delete
            </a>
          </span>

          <!-- created date -->
          <span v-if="comment.createdDate != null" class="discrete">
            &#8226; {{ comment.createdDate | moment("from", true) }} ago
          </span>
          <!-- modified date -->
          <span
            v-if="comment.modifiedDate != comment.createdDate"
            class="discrete"
          >
            &#8226;
            <i>
              last update
              {{ comment.modifiedDate | moment("from", true) }} ago</i
            >
          </span>
        </div>

        <reactions
          :anchor="comment.childAnchor"
          :isReactionPickerDisplayed="isReactionPickerDisplayed"
          @close-picker="closePicker"
        />

        <!-- Child Comments-->
        <comment-anchor
          :anchor="comment.childAnchor"
          :isCommentFormDisplayedByDefault="isCommentFormDisplayed"
          :isCommentListDisplayedByDefault="isCommentListDisplayed"
          @close-form="isCommentFormDisplayed = false"
          :isSelected="isReplyFocus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import Reactions from '@/components/Avalia/Comments/Reactions.vue';
// https://www.npmjs.com/package/emoji-mart-vue-fast for the emoji picker used
import Swal from 'sweetalert2';
import notify from '@/utils/notificationsUtils';

export default {
  name: 'comment',
  props: {
    comment: {
      type: Object,
    },
  },
  components: {
    Reactions,
  },
  data() {
    return {
      isCommentFormDisplayed: false,
      isCommentListDisplayed: false,
      isReactionPickerDisplayed: false,
      isEditFormDisplayed: false,
      editedComment: '',
    };
  },
  watch: {
    isEditFormDisplayed(newVal) {
      if (newVal) {
        // Focus edit input
        this.focusEditInput();
      }
    },
  },
  methods: {
    toggleReactionPicker() {
      if (this.isReactionPickerDisplayed) {
        this.isReactionPickerDisplayed = false;
      } else {
        this.isReactionPickerDisplayed = true;
      }
    },
    closePicker() {
      this.isReactionPickerDisplayed = false;
    },
    toggleReplyForm() {
      this.isCommentFormDisplayed = !this.isCommentFormDisplayed;
    },
    toggleEditForm() {
      this.isEditFormDisplayed = !this.isEditFormDisplayed;

      if (this.isEditFormDisplayed === true) {
        this.editedComment = this.comment.text;
      }
    },
    closeEditForm() {
      this.isEditFormDisplayed = false;
      this.editedComment = '';
    },
    editComment() {
      api.assess
        .patch(`/comments/${this.comment.id}`, {
          text: this.editedComment,
        })
        .then((response) => {
          this.$emit('update-comment', {
            text: this.editedComment,
          });
          this.toggleEditForm();
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Update Comment : ${error.response.data.message
              || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    deleteComment() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'md-button md-success btn-fill',
          cancelButton: 'md-button md-danger btn-fill',
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure you want to delete this comment ?',
          text: `"${this.comment.text}"`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            api.assess.delete(`/comments/${this.comment.id}`).then((response) => {
              // Todo not modify prop directly
              this.comment.text = 'This comment has been deleted';
              this.comment.author = null;
              this.comment.modifiedDate = new Date();
              this.$emit('delete-comment');
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'Your comment has been deleted.',
                'success',
              );
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              'Cancelled',
              'Your comment is safe :)',
              'error',
            );
          }
        });
    },
    focusEditInput() {
      this.$nextTick(() => {
        this.$refs.editInput.$el.focus();
      });
    },
  },
  computed: {
    replyButtonText() {
      if (this.isCommentFormDisplayed) {
        return 'cancel';
      }
      return 'reply';
    },
    currentUserEmail() {
      return this.$store.getters['users/GET_CURRENT_USER_EMAIL'];
    },
    isReplyFocus() {
      return this.isCommentFormDisplayed;
    },
  },
};
</script>

<style scoped>
.observation {
  margin-top: 0.5rem;
  /*box-shadow: -5px 0 5px -5px #888;*/
  padding: 0px 0px 0px 0px;
  /*border-top: 1px solid rgba(87, 87, 87, 0.088);*/
}

.observation-header {
  padding: 0px 0px 0px 0px;
}

.observation-body-inner {
  padding-left: 10px;
  border-left: 2px solid rgb(156, 156, 156);
}
.observation-body-outter {
  padding-right: 0px;
  padding-left: 11px;
}

.authorName {
  font-size: 1rem;
  font-weight: 400;
}

.observation-text {
  font-size: 0.95rem;
}

.text-right {
  text-align: right;
}

.button-small-height {
  height: 24px;
}
.discrete {
  color: rgba(87, 87, 87, 1);
}
a.discrete {
  cursor: pointer;
}
</style>
