<template>
  <textarea class="text-area" :class="computeCursorClass" v-model.lazy="text" :disabled="isTaskReadOnly"></textarea>
</template>

<script>
export default {
  name: 'text-question',
  props: {
    question: Object,
    isTaskReadOnly: Boolean,
  },
  data() {
    return {
      text: '',
    };
  },
  watch: {
    question: {
      handler(newQuestion) {
        if (newQuestion && newQuestion.answer.answerContent) {
          this.text = newQuestion.answer.answerContent;
        }
      },
      deep: true,
    },
    text: {
      handler(newText) {
        if (newText !== this.question.answer.answerContent) {
          this.editorContentChanged(newText);
        }
      },
    },
  },
  computed: {
    computeCursorClass() {
      return this.isTaskReadOnly ? 'cursor-not-allowed' : '';
    },
  },
  created() {
    if (this.question.answer.answerContent) {
      this.text = this.question.answer.answerContent;
    }
  },
  methods: {
    editorContentChanged(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-area {
  border: 1px solid #bcc6cc;
  padding: 15px;
  width: 100%;
  min-height: 0px;
  height: auto;
}
.field:after {
  border-style: none!important;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
