<template>
  <component
    :is="getQuestionComponent(question.questionType)"
    :question="question"
    :isTaskReadOnly="readOnly"
    @input="onAnsweringQuestion($event, question)"
    @fileInput="onAnsweringFileQuestion($event, question)"
    @downloadFile="onDownloadFile"
    @deleteFile="onDeleteFile"
  >
  </component>
</template>
<script>
import TextQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/TextQuestion.vue';
import BoolQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/BoolQuestion.vue';
import DateQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/DateQuestion.vue';
import SliderQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/SliderQuestion.vue';
import MultipleChoiceQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/MultipleChoiceQuestion.vue';
import TableQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/TableQuestion.vue';
import FileQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/FileQuestion.vue';
import MultipleChoiceSingleAnswerWithValueQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/MultipleChoiceSingleAnswerWithValueQuestion.vue';
import { getComponentFromQuestionType } from '@/utils/questionUtils';

export default {
  name: 'question-content',
  components: {
    TextQuestion,
    BoolQuestion,
    DateQuestion,
    SliderQuestion,
    'table-question': TableQuestion,
    MultipleChoiceQuestion,
    MultipleChoiceSingleAnswerWithValueQuestion,
    FileQuestion,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      answers: [],
    };
  },
  methods: {
    getQuestionComponent(questionType) {
      return getComponentFromQuestionType(questionType);
    },
    onAnsweringQuestion(value, question) {
      this.$emit('onAnsweringQuestion', { value, question });
    },
    onAnsweringFileQuestion(value, question) {
      this.$emit('onAnsweringFileQuestion', { value, question });
    },
    onDownloadFile($event) {
      this.$emit('downloadFile');
    },
    onDeleteFile($event) {
      this.$emit('deleteFile');
    },
  },
};
</script>
