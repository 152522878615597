<template>
  <div>
    <div>
      <span v-for="reaction in reactions" :key="reaction.key">
        {{
          reaction.userEmails.length +
            " " +
            emojiIndex.emoji(reaction.emoji).native
        }}
      </span>
    </div>
    <div
      v-if="isReactionPickerDisplayed"
      class="custom-tooltip"
      v-click-outside="hideReactionPicker"
    >
      <md-card>
        <picker
          ref="pickerElement"
          :data="emojiIndex"
          :title="'Pick your reaction'"
          :emoji="'point_up'"
          @select="onNewEmojiReaction"
        />
        <div>
          <button class="md-button md-danger" @click="hideReactionPicker">
            cancel
          </button>
        </div>
      </md-card>
    </div>
  </div>
</template>
<script>
import api from '@/api/api';
import data from 'emoji-mart-vue-fast/data/all.json';
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import notify from '@/utils/notificationsUtils';

export default {
  name: 'reaction',
  components: {
    Picker,
  },
  props: {
    anchor: {
      type: Object,
    },
    isReactionPickerDisplayed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emojiIndex: null,
      reactions: [],
    };
  },
  created() {
    this.emojiIndex = new EmojiIndex(data);
    this.fetchReactions();
  },
  methods: {
    handleClick(e) {
      e.stopPropagation();
    },
    onNewEmojiReaction(emoji) {
      const caller = this;
      // console.log("User :" + caller.$store.state.users.currentUser);
      return api.assess
        .post('/reactions', {
          anchorId: this.anchor.id,
          userEmail: caller.$store.state.users.currentUser.sub,
          emoji: emoji.short_name,
        })
        .then(this.fetchReactions)
        .then(this.hideReactionPicker)
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Send Reactions : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    hideReactionPicker(event) {
      // document.removeEventListener("click", this.hideReactionPicker);
      this.$emit('close-picker');
    },
    fetchReactions() {
      return api.assess
        .get(`/reactions/${this.anchor.id}/reduced`)
        .then((response) => {
          this.reactions = Array.from(response.data);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Reactions : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-tooltip {
  position: absolute;
  top: 15px;
  z-index: 9999;
}
</style>
