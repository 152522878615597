<template>
  <div class="md-layout options-list">
    <div class="md-layout-item md-size-100">
      <md-radio
        v-model="boolValue"
        :value="true"
        :disabled="readOnly"
        >True</md-radio
      >
    </div>
    <div class="md-layout-item md-size-100">
      <md-radio
        v-model="boolValue"
        :value="false"
        :disabled="readOnly"
        >False</md-radio
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'form-template-bool-question',
  components: { },
  props: {
    question: Object,
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      boolValue: null,
    };
  },
  computed: {
  },
  watch: {},
  created() {
  },
  mounted() {
    // On mounted
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped></style>
