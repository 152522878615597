<template>
  <component
    :is="getQuestionComponent(questionType)"
    :question="questionEmbedded"
    :isTaskReadOnly="readOnly"
    @input="onAnsweringQuestion($event)"
  >
  </component>
</template>
<script>
import TextQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/TextQuestion.vue';
import BoolQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/BoolQuestion.vue';
import DateQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/DateQuestion.vue';
import SliderQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/SliderQuestion.vue';
import MultipleChoiceQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/MultipleChoiceQuestion.vue';
import TableQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/TableQuestion.vue';
import FileQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/FileQuestion.vue';
import MultipleChoiceSingleAnswerWithValueQuestion from '@/components/Avalia/TaskDetails/Form/QuestionType/MultipleChoiceSingleAnswerWithValueQuestion.vue';
import { getComponentFromQuestionType } from '@/utils/questionUtils';

export default {
  name: 'question-embedded-content',
  components: {
    TextQuestion,
    BoolQuestion,
    DateQuestion,
    SliderQuestion,
    'table-question': TableQuestion,
    MultipleChoiceQuestion,
    MultipleChoiceSingleAnswerWithValueQuestion,
    FileQuestion,
  },
  props: {
    questionType: {
      type: String,
      required: true,
    },
    answer: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      questionEmbedded: {},
    };
  },
  methods: {
    getQuestionComponent(questionType) {
      return getComponentFromQuestionType(questionType);
    },
    onAnsweringQuestion(value) {
      this.$emit('onAnsweringQuestion', { answerContent: value, answerType: this.answer.answerType });
    },
  },
  created() {
    this.questionEmbedded.questionType = this.questionType;
    this.questionEmbedded.answer = this.answer;
  },
  watch: {
    answer(newAnswer) {
      this.$set(this.questionEmbedded, 'answer', newAnswer);
    },
  },
};
</script>
