<template>
  <div class="md-layout">
    <el-date-picker
      v-model="date"
      type="date"
      placeholder="Choose a Date"
      :disabled="isTaskReadOnly">
    </el-date-picker>
  </div>
</template>

<script>
import { DatePicker } from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

// language configuration
locale.use(lang);

export default {
  name: 'date-question',
  components: {
    [DatePicker.name]: DatePicker,
  },
  props: {
    question: Object,
    isTaskReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      date: null,
    };
  },
  watch: {
    question: {
      handler(newQuestion) {
        if (newQuestion && newQuestion.answer.answerContent) {
          this.date = new Date(newQuestion.answer.answerContent);
        }
      },
    },
    date: {
      handler(newDate) {
        const newAnswerContent = this.question.answer.answerContent;
        if (this.date && newDate.getTime() !== new Date(newAnswerContent).getTime()) {
          this.datePickerChanged(this.date);
        }
      },
    },
  },
  computed: {
    dateFormat: {
      get() {
        return this.$material.locale.dateFormat;
      },
    },
  },
  created() {
    this.$material.locale.dateFormat = 'dd/MM/yyyy';
    if (this.question.answer.answerContent) {
      this.date = new Date(this.question.answer.answerContent);
    }
  },
  mounted() {
    // On mounted
  },
  methods: {
    datePickerChanged(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-area {
  border: 1px solid #bcc6cc;
  padding: 15px;
  width: 100%;
  min-height: 0px;
  height: auto;
}
.field:after {
  border-style: none!important;
}
</style>
