<template>
<div class="md-layout">
  <div v-if="!isList" class="titles-table-question">
    <div
      class="item md-layout-item nopadding"
      :class="tableColumnCSS"
      v-for="column in question.columns"
      :key="column.label"
    >
      <label class="question-text">{{ column.label }}</label>
    </div>
  </div>
  <div
    class="item md-layout md-layout-item md-size-100 nopadding"
    v-for="(line, lineIndex) in tableAnswers"
    :key="'line-' + lineIndex"
  >
    <div
      class="question-embedded item md-layout-item"
      :class="tableColumnCSS"
      v-for="(column, columnIndex) in question.columns"
      :key="`question-${columnIndex}-${tableAnswers[lineIndex][columnIndex].answerContent}`"
    >
      <question-embedded-content
        :questionType="column.question.questionType"
        :answer="tableAnswers[lineIndex][columnIndex]"
        :readOnly="isTaskReadOnly"
        @onAnsweringQuestion="(value) => onAnsweringQuestion(lineIndex, columnIndex, value)"
      />
    </div>
    <div class="item md-layout-item md-size-10">
      <span @click="deleteRow(lineIndex)"><md-icon class="icon-action icon-delete">delete</md-icon></span>
    </div>
  </div>
  <div class="md-layout-item md-size-100 nopadding"><md-button class="add-line-button" @click="addNewLine()">+ Add</md-button></div>
</div>
</template>

<script>
import QuestionEmbeddedContent from '@/components/Avalia/TaskDetails/Form/Question/QuestionEmbeddedContent.vue';

export default {
  name: 'table-question',
  components: {
    QuestionEmbeddedContent,
  },
  props: {
    question: Object,
    isTaskReadOnly: Boolean,
  },
  data() {
    return {
      tableAnswers: [],
    };
  },
  computed: {
    tableColumnCSS() {
      const deleteColumnSize = 10;
      const percentColumn = Math.floor((100 - deleteColumnSize) / this.nbColumns);
      return `md-size-${percentColumn}`;
    },
    nbColumns() {
      return this.question.columns.length;
    },
    isList() {
      return this.nbColumns === 1;
    },
  },
  watch: {},
  created() {
    // On created
    this.tableAnswers = this.question.answer.answerContent;
  },
  mounted() {
    // On mounted
  },
  methods: {
    onAnsweringQuestion(lineIndex, columnIndex, value) {
      this.$set(this.tableAnswers[lineIndex], columnIndex, value);
      this.$emit('input', this.tableAnswers);
    },
    addNewLine() {
      const newLine = this.tableAnswers[0].map((q) => this.resetQuestion(q));
      this.tableAnswers.push(newLine);
      this.$emit('input', this.tableAnswers);
    },
    deleteRow(lineIndex) {
      if (this.tableAnswers.length > 1) {
        this.tableAnswers.splice(lineIndex, 1);
      } else {
        let answersFirstLine = this.tableAnswers[0];
        answersFirstLine = answersFirstLine.map((q) => this.resetQuestion(q));
        this.$set(this.tableAnswers, 0, answersFirstLine);
      }
      this.$emit('input', this.tableAnswers);
    },
    resetQuestion(question) {
      if (question.answerType === 'TEXT_ANSWER') {
        return {
          answerType: question.answerType,
          answerContent: '',
        };
      }
      return question;
    },
  },
};
</script>

<style lang="scss" scoped>
.question-embedded {
  padding: 0px 10px 0px 0px;
}
.add-line-button {
  margin-left: 0rem;

  color: $avalia-gray !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: $avalia-gray !important;
    opacity: 0.8 !important;
    border-color: $avalia-gray !important;
    &:active {
      color: $avalia-gray !important;
      background-color: white !important;
      border-style: solid !important;
      border-width: 2px !important;
    }
  }

  &:focus {
    color: $avalia-gray !important;
    background-color: white !important;
    border-style: solid !important;
    border-width: 2px !important;
    &:hover {
      color: white !important;
      background-color: $avalia-gray !important;
      opacity: 0.8 !important;
      border-color: $avalia-gray !important;
    }
  }
}
.titles-table-question {
  width: 100%;
  display: flex;
}
</style>
