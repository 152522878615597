<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <!--
            <ul>
                <li>
                    <a href="https://avalia.io">
                        Avalia.io
                    </a>
                </li>
            </ul>
            -->
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href="https://avalia.io" target="_blank">Avalia Systems</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
