<template>
  <div class="md-layout options-list">
    <div class="md-layout-item md-size-100">
      <md-radio
        v-model="boolValue"
        :value="true"
        :disabled="isTaskReadOnly"
        @change="radioButtonValueChanged"
        >True</md-radio
      >
    </div>
    <div class="md-layout-item md-size-100">
      <md-radio
        v-model="boolValue"
        :value="false"
        :disabled="isTaskReadOnly"
        @change="radioButtonValueChanged"
        >False</md-radio
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'bool-question',
  components: { },
  props: {
    question: Object,
    isTaskReadOnly: Boolean,
  },
  data() {
    return {
      boolValue: null,
    };
  },
  computed: {
  },
  watch: {},
  created() {
    // On created
    this.boolValue = this.question.answer.answerContent;
  },
  mounted() {
    // On mounted
  },
  methods: {
    radioButtonValueChanged(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
