/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';

import config from './modules/config';
import users from './modules/users';
import projects from './modules/projects';
import project from './modules/project';
import projectsFiltersAndPagination from './modules/projectsFiltersAndPagination';
import tags from './modules/tags';
import task from './modules/task';
import events from './modules/events';
import feedbacks from './modules/feedbacks';
import form from './modules/form';
import notes from './modules/notes';
import formTemplates from './modules/formTemplates';
import playbooks from './modules/playbooks';
import playbookEventsFilters from './modules/playbookEventsFilters';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    config,
    users,
    projects,
    project,
    projectsFiltersAndPagination,
    tags,
    task,
    events,
    feedbacks,
    form,
    notes,
    formTemplates,
    playbooks,
    playbookEventsFilters,
  },
});

export default store;
