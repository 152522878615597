<template>
  <div class="md-layout">
    <div class="nopadding md-layout-item md-large-size-90 file-input-container">
      <md-field>
        <label>Select file</label>
        <md-file class="custom-file-input" ref="file" v-model="fileName" accept="image/*" @md-change="fileChanged" :disabled="isTaskReadOnly"/>
      </md-field>
    </div>
    <div v-if="hasFileUploaded" class="md-layout-item md-size-10 actions-container">
          <span  @click="downloadFile">
            <md-icon class="icon-action icon-download">cloud_download</md-icon>
            <md-tooltip md-delay="300">Download File</md-tooltip>
          </span>
          <span v-if="!isTaskReadOnly" @click="deleteAssociatedFile">
            <md-icon class="icon-action icon-delete">delete</md-icon>
            <md-tooltip md-delay="300">Delete File</md-tooltip>
          </span>
      </div>
  </div>
</template>

<script>
export default {
  name: 'file-question',
  props: {
    question: Object,
    isTaskReadOnly: Boolean,
  },
  data() {
    return {
      fileName: null,
      fileObjectId: '',
    };
  },
  watch: {
    question: {
      handler(newQuestion) {
        if (newQuestion && newQuestion.answer) {
          this.fileName = this.question.answer.name ? this.question.answer.name : '';
          this.fileObjectId = this.question.answer.fileObjectId
            ? this.question.answer.fileObjectId
            : '';
        }
      },
    },
    fileName: {
      handler(newName) {},
    },
  },
  computed: {
    hasFileUploaded() {
      return this.question.answer.name && this.question.answer.name !== '' && this.question.answer.fileObjectId && this.question.answer.fileObjectId !== '';
    },
  },
  created() {
    if (this.question.answer) {
      this.fileName = this.question.answer.name ? this.question.answer.name : '';
      this.fileObjectId = this.question.answer.fileObjectId
        ? this.question.answer.fileObjectId
        : '';
    }
  },
  mounted() {
    // On mounted
  },
  methods: {
    fileChanged(value) {
      if (this.$refs.file.$refs.inputFile.files[0]) {
        this.$emit('fileInput', this.$refs.file.$refs.inputFile.files[0]);
      }
    },
    downloadFile() {
      if (this.hasFileUploaded) {
        this.$emit('downloadFile');
      }
    },
    deleteAssociatedFile() {
      if (this.hasFileUploaded) {
        this.$emit('deleteFile');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-area {
  border: 1px solid #bcc6cc;
  padding: 15px;
  width: 100%;
  min-height: 0px;
  height: auto;
}
.field:after {
  border-style: none !important;
}
.actions-container{
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
}
.file-input-container {
  &::v-deep .md-input {
    cursor: pointer;
  }
}
.custom-file-input {
  &::v-deep svg {
    fill: $avalia-gray!important; //fill: $avalia-black!important;
  }
}

</style>
